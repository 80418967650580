import { createContext } from 'preact';
import { DrillEquipment } from './DrillEquipmentSelect';

interface DrillerProdContextProps {
    equipments: DrillEquipment;
    setEquipments: (equipments: DrillEquipment) => void;
}

const defaultContextValue: DrillerProdContextProps = {
    equipments: {
        compressor: { equipment_id: undefined, start_time: 0, end_time: 0 },
        drill: { equipment_id: undefined, start_time: 0, end_time: 0 },
        truck: { equipment_id: undefined, start_time: 0, end_time: 0 },
    },
    setEquipments: () => {},
};

const DrillerProdContext = createContext<DrillerProdContextProps>(defaultContextValue);


export default DrillerProdContext;