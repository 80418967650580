import {
    getAddresses,
    getEmployees,
    getEquipments,
    getLocations,
    getMaterials,
    getProfessions,
    getProjects,
    getProjectsWithTask,
    getPurchaseOrders,
    getSuppliers,
    getTasks
} from '@/api/resources'
import Input from '@/components/forms/Input'
import globalContext from '@/context'
import Fuse from 'fuse.js'
import { sortBy } from 'lodash-es'
import { useContext, useEffect, useRef, useState } from 'preact/hooks'
import LoadingInput from './LoadingInput'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { isQueryLoading } from '@/helpers'

type Props<T> = {
    name: string
    label: string
    isLoading: boolean
    resource: T[] | undefined
    selectedId?: number | undefined
    onMatch?: (match: T | undefined) => void
} & JSX.HTMLAttributes<HTMLInputElement>

export const FuzzySelect = <T extends Api.Resource,>({ name, label, isLoading, resource, ...p }: Props<T>) => {
    const [, actions] = useContext(globalContext)
    const { t } = useTranslation('forms')
    const [items, setItems] = useState<T[]>([])
    const [selected, setSelected] = useState<T>()
    const [inputKey, setInputKey] = useState(Math.random());
    const fuse = useRef<Fuse<T>>()
    const allItems = useRef<T[]>([])
    
    if (p.defaultValue && selected === undefined) {
        const val = items.find(item => item.id === Number.parseInt(p.defaultValue))
        setSelected(val)
        if (p.onMatch) {
            p.onMatch(val)
        }
    }

    const val = items.find(item => item.id === selected?.id)

    if (val === undefined && selected !== undefined) {
        setSelected(undefined)
    }

    function handleChange(e: InputEvent) {
        const input = e.target as HTMLInputElement
        if (!input.value) {
            setItems(allItems.current)
            setSelected(undefined)
            if (p.onMatch) {
                p.onMatch(undefined)
            }
            return
        }
        const results = fuse.current?.search(input.value)
        setItems(results?.map(r => r.item) ?? [])
        const match = items.find(it => it.name === input.value)
        setSelected(match)
        if (p.onMatch) {
            p.onMatch(match)
        }
    }

    function handleKeypress(e: KeyboardEvent) {
        if (e.key === 'Enter') {
            e.preventDefault()
            setSelected(items.at(0))
        }
    }

    useEffect(() => {
        fuse.current = new Fuse(resource as readonly T[], { keys: ['name'] })
        allItems.current = resource ?? []
        setItems(resource ?? [])
        if (p.selectedId) {
            const val = resource?.find(item => item.id === Number.parseInt(p.selectedId))
            setSelected(val)
        }
    }, [resource, p.selectedId])

    if (isLoading) {
        return (
            <LoadingInput
                label={label}
                placeholder={t('forms:loading') as string}
                {...p}
            />
        )
    }

    return (
        <Input
            {...p}
            label={label}
            onChange={handleChange as any}
            required={p?.required}
            onKeyPress={handleKeypress}
            value={selected?.name}
            list={`${name}-list`}
            key={inputKey}
            disabled={resource?.length === 0}
        >
            <datalist id={`${name}-list`}>
                {sortBy(items, 'name').map(item => (
                    <option value={item.name} />
                ))}
            </datalist>
            <input hidden name={name} value={selected?.id} />
        </Input>
    )
}

type MakeFuzzySelectProps = {
    name: string
    label: string
    onMatch?: (match: object | undefined) => void
} & JSX.HTMLAttributes<HTMLInputElement>

function makeFuzzySelect<T extends Api.Resource>(getResource: () => Promise<T[]>, resourceName: string, filter?: (items: T[]) => T[]): FC<MakeFuzzySelectProps> {
    return ({ name, label, ...p }) => {
        const { status: status, fetchStatus: fetchStatus, data: resource, error: error } = useQuery<T[]>({
            queryKey: [resourceName],
            queryFn: getResource,
            select: filter
        })
        return (
            <FuzzySelect
                name={name}
                label={label}
                isLoading={isQueryLoading(status, fetchStatus)}
                resource={resource}
                {...p}
            />
        )
    }
}

export const TaskSelect = makeFuzzySelect<Api.Task>(getTasks, 'tasks')
export const TaskForProjectSelect = makeFuzzySelect<Api.Task>(getTasks, 'tasks')
export const ProjectSelect = makeFuzzySelect<Api.Project>(getProjects, 'projects')
export const ProjectWithTaskSelect = makeFuzzySelect<Api.Project>(getProjectsWithTask, 'projects-tasks')
export const EmployeeSelect = makeFuzzySelect<Api.Employee>(getEmployees, 'employees')
export const EquipmentSelect = makeFuzzySelect<Api.Equipment>(getEquipments, 'equipments')
export const DrillSelect = makeFuzzySelect<Api.Equipment>(getEquipments, 'equipments', (items) => items.filter(i => i.status.slug === 'drilling-machine' || i.status.slug === 'air-drill'))
export const MaterialSelect = makeFuzzySelect<Api.Material>(getMaterials, 'materials')
export const LocationSelect = makeFuzzySelect<Api.Resource>(getLocations, 'locations')
export const PurchaseOrderSelect = makeFuzzySelect<Api.PurchaseOrder>(getPurchaseOrders, 'purchase-orders')
export const ProfessionSelect = makeFuzzySelect<Api.Profession>(getProfessions, 'professions')
export const ClientSelect = makeFuzzySelect<Api.Supplier>(getSuppliers, 'suppliers')
export const AddressSelect = makeFuzzySelect<Api.Address>(getAddresses, 'addresses')
export const BorerSelect = makeFuzzySelect<Api.Equipment>(getEquipments, 'equipments', (items) => items.filter(i => i.status.slug === 'borer'))
export const TruckSelect = makeFuzzySelect<Api.Equipment>(getEquipments, 'equipments', (items) => items.filter(i => i.status.slug === 'truck'))
