import { useContext } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import globalContext from '@/context'
import { DrillSelect, EquipmentSelect, TruckSelect } from '@/components/forms/FuzzySelect'
import Input from '@/components/forms/Input'
import DrillerProdContext from './DrillerProdContext'
import { useQuery } from '@tanstack/react-query'
import { getEquipments } from '@/api/resources'

type Props = {
    d?: any[]
    defaultValue?: DrillEquipment
    defaultCollapsed?: boolean
}

type EquipmentUsage = {
    equipment_id: number | undefined
    start_time: number
    end_time: number
}

export type DrillEquipment = {
    [key: string]: any
    compressor: EquipmentUsage
    drill: EquipmentUsage
    truck: EquipmentUsage
}

const DrillEquipmentSelect: FC<Props> = ({ d, ...p }) => {
    const { t } = useTranslation('driller')
    const [, actions] = useContext(globalContext);
    const context = useContext(DrillerProdContext);
    const { data: equipmentsList, isLoading, error } = useQuery({
        queryKey: ['equipments'],
        queryFn: getEquipments,
    })

    const equipments = context?.equipments
    const setEquipments = context?.setEquipments

    const onEquipmentMatch = (e: Api.Equipment, type: keyof DrillEquipment) => {
        if (setEquipments != undefined && equipments != undefined) {
            setEquipments({
                ...equipments,
                [type]: { ...equipments[type], equipment_id: e.id } as EquipmentUsage
            } as DrillEquipment);
        }
    }

    const compressorRequired = equipmentsList?.filter(e => e.status.slug === 'air-drill').find(e => e.id === equipments?.drill.equipment_id) !== undefined
    const drillDuration = (equipments?.drill?.end_time ?? 0) - (equipments?.drill?.start_time ?? 0)
    const compressorDuration = (equipments?.compressor?.end_time ?? 0) - (equipments?.compressor.start_time ?? 0)
    const truckDuration = (equipments?.truck?.end_time ?? 0) - (equipments?.truck.start_time ?? 0)

    return (
        <fieldset>
            <legend>{t('equipments')}</legend>
            <fieldset>
                <legend>{t('drill')}</legend>
                <DrillSelect
                    className='span6'
                    type='text'
                    selectedId={equipments?.drill.equipment_id}
                    onMatch={(match) => onEquipmentMatch(match as Api.Equipment, 'drill')}
                    name='equipments[0][equipment_id]'
                    label={t('drill_number')}
                />
                <Input
                    className='span2'
                    type='number'
                    step={'any'}
                    min={0}
                    value={equipments?.drill.start_time}
                    onChange={(e) => setEquipments({ ...equipments, drill: { ...equipments.drill, start_time: e.target.value } })}
                    name='equipments[0][start_time]'
                    label={t('start_time')}
                />
                <Input
                    className='span2'
                    type='number'
                    step={'any'}
                    min={0}
                    value={equipments?.drill.end_time}
                    onChange={(e) => setEquipments({ ...equipments, drill: { ...equipments.drill, end_time: e.target.value } })}
                    name='equipments[0][end_time]'
                    label={t('end_time')}
                />
                <Input
                    readonly
                    className='span2'
                    type='number'
                    step={'any'}
                    min={0}
                    value={drillDuration}
                    name='equipments[0][duration]'
                    label={t('total_hours')}
                />
            </fieldset>
            <fieldset>
                <legend>{t('compressor')}</legend>
                <EquipmentSelect
                    className='span6'
                    type='text'
                    selectedId={equipments?.compressor.equipment_id}
                    required={compressorRequired}
                    onMatch={(match) => onEquipmentMatch(match as Api.Equipment, 'compressor')}
                    name='equipments[1][equipment_id]'
                    label={t('compressor_number')}
                />
                <Input
                    className='span2'
                    type='number'
                    step={'any'}
                    min={0}
                    required={compressorRequired}
                    value={equipments?.compressor.start_time}
                    onChange={(e) => setEquipments({ ...equipments, compressor: { ...equipments.compressor, start_time: e.target.value } })}
                    name='equipments[1][start_time]'
                    label={t('start_time')}
                />
                <Input
                    className='span2'
                    type='number'
                    step={'any'}
                    min={0}
                    required={compressorRequired}
                    value={equipments.compressor.end_time}
                    onChange={(e) => setEquipments({ ...equipments, compressor: { ...equipments.compressor, end_time: e.target.value } })}
                    name='equipments[1][end_time]'
                    label={t('end_time')}
                />
                <Input
                    readonly
                    className='span2'
                    type='number'
                    step={'any'}
                    min={0}
                    value={compressorDuration}
                    name='equipments[1][duration]'
                    label={t('total_hours')}
                />
            </fieldset>
            <fieldset>
                <legend>{t('truck')}</legend>
                <TruckSelect
                    className='span6'
                    type='text'
                    selectedId={equipments?.truck.equipment_id}
                    onMatch={(match) => onEquipmentMatch(match as Api.Equipment, 'truck')}
                    name='equipments[2][equipment_id]'
                    label={t('truck_number')}
                />
                <Input
                    className='span2'
                    type='number'
                    step={'any'}
                    min={0}
                    value={equipments.truck.start_time}
                    onChange={(e) => setEquipments({ ...equipments, truck: { ...equipments.truck, start_time: e.target.value } })}
                    name='equipments[2][start_time]'
                    label={t('start_time')}
                />
                <Input
                    className='span2'
                    type='number'
                    step={'any'}
                    min={0}
                    value={equipments.truck.end_time}
                    onChange={(e) => setEquipments({ ...equipments, truck: { ...equipments.truck, end_time: e.target.value } })}
                    name='equipments[2][end_time]'
                    label={t('end_time')}
                />
                <Input
                    readonly
                    className='span2'
                    step={'any'}
                    min={0}
                    type='number'
                    value={truckDuration}
                    name='equipments[2][duration]'
                    label={t('total_hours')}
                />
            </fieldset>
        </fieldset>
    )
}

export default DrillEquipmentSelect
