import { getMaterials } from '@/api/resources'
import Input from '@/components/forms/Input'
import { groupBy } from 'lodash-es'
import { useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import { filterComponentData } from '@/utils'

type Props = {
	d?: any[]
	filterkey: string
}
const Materials: FC<Props> = ({ d, ...p }) => {
	const { t } = useTranslation('blaster')
	const [materials, setMaterials] = useState<Api.Material[]>([])

	useEffect(() => {
		getMaterials().then((items: Array<Api.Material>) => {
			if (p.filterkey) {
				items = filterComponentData(items, p.filterkey)
			}
			setMaterials(items)
		})
	}, [])

	const mappedMaterials = materials.map(m => ({
		...m,
		category_name: m.status.name
	}))
	return (
		<fieldset>
			<legend>{t('materials')}</legend>
			{Object.entries(groupBy(mappedMaterials, 'category_name')).map(([category, ms]) => (
				<fieldset>
					<legend>{category}</legend>
					{ms.map(m => (
						<Input
							label={m.name}
							className='span3'
							name={`form[materials][${m.id}]`}
							defaultValue={d && d[m.id]}
							decimal
						/>
					))}
				</fieldset>
			))}
		</fieldset>
	)
}
export default Materials
